<template>
    <div v-if="items.length!==0" class="card bs-4 p-0 pb-3">
        <h5 class="pt-3 pl-3">Items Not Received</h5>
        <custom-simple-table class="table-borderless mt-4 mb-0" ref="table" title="Items" :fields="fields"
                             :data="items">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn class="btn-basic-b" @click="removeItem(rowData)" size="xs" icon="fa fa-trash" text="Remove"/>
                </div>
            </template>
            <template #no="rowData">
                <span>{{ getSlNo(rowData.rowData, items) }}</span>
            </template>
        </custom-simple-table>
    </div>
</template>

<script>
export default {
    name  : 'ItemsNotReceived',
    props : {
        data : { type : Array }
    },
    data () {
        return {
            DataLoading : false,
            items       : [],
            fields      : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'product_name',
                    sortField : 'product_name',
                    title     : 'Items'
                },
                {
                    name      : 'price',
                    sortField : 'price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'tax',
                    sortField : 'tax',
                    title     : 'Tax'
                },
                {
                    name      : 'total',
                    sortField : 'total',
                    title     : 'Total'
                },
                {
                    name      : '__slot:actions',
                    sortField : '',
                    title     : 'Actions'
                }
            ]
        };
    },
    watch : {
        data : {
            handler () {
                this.setData();
            }
        }
    },
    mounted () {
        this.setData();
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        },
        setData () {
            if (this.data) {
                this.items = this.data;
            }
        },
        removeItem (rowData) {
            this.$emit('remove2', rowData);
        }
    }
};
</script>
