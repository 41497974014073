<template>
    <div>
        <div v-if="order" class="card bs-4 p-0 pb-3">
            <h5 class="p-3 bb-1">Purchase Order Info</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Purchase ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order.order_id }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Vendor</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order.vendor }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order.date }}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order.status }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Created By</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order.created_by }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Remarks</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order.remarks }}</div>
                <div class="col-1"/>
            </div>
            <custom-simple-table v-if="order.items" class="table-borderless mt-4 mb-0" ref="table" title="Items"
                                 :fields="fields" :data="order.items">
                <template #no="rowData">
                    <span>{{ getSlNo(rowData.rowData, order.items) }}</span>
                </template>
                <template #actions="{rowData}">
                    <div class="btn-group-tight">
                        <btn v-if="showButton(rowData)!==false" class="btn-basic-b" size="xs"
                             @click="setReceived(rowData)" text="Receive"/>
                        <btn v-if="showButton(rowData)!==false" class="btn-basic-b" size="xs"
                             @click="setNotReceived(rowData)" text="Not Receive"/>
                        <btn v-if="showReceivedButton(rowData)===true" size="xs" text="Received"/>
                        <btn v-if="showNotReceivedButton(rowData)===true" size="xs" text="Not Received"/>
                    </div>
                </template>
            </custom-simple-table>
        </div>
        <div class="mt-5">
            <items-received v-if="receive.length!==0" @remove1="updateList" :data="receive"/>
            <items-not-received v-if="notReceive.length!==0" @remove2="updateList1" :data="notReceive"/>
        </div>
        <div class="card bs-4 p-2 mt-5">
            <div class="row p-3">
                <div class="col-1 label">Remarks</div>
                <div class="col-5 label">
                    <validated-input class="mb-0" v-model="remarks" placeholder="Enter remarks" name="Remarks"
                                     :rules="{required : true}"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemsReceived from '../../../../WarehouseAdmin/PurchaseEntry/PurchaseEntry/TableComponents/ItemsReceived';
import ItemsNotReceived from '../../../../WarehouseAdmin/PurchaseEntry/PurchaseEntry/TableComponents/ItemsNotReceived';
import axios from 'secure-axios';
import urls from '../../../../../data/urls';

export default {
    name       : 'PurchaseEntryDetailsTable',
    components : { ItemsNotReceived, ItemsReceived },
    props      : {
        data : { type : Object }
    },
    data () {
        return {
            DataLoading : false,
            fields      : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'product_name',
                    sortField : 'product_name',
                    title     : 'Items'
                },
                {
                    name      : 'price',
                    sortField : 'price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'tax',
                    sortField : 'tax',
                    title     : 'Tax'
                },
                {
                    name      : 'total',
                    sortField : 'total',
                    title     : 'Total'
                },
                {
                    name      : '__slot:actions',
                    sortField : '',
                    title     : 'Actions'
                }
            ],
            order      : {},
            entry_id   : '',
            remarks    : '',
            receive    : [],
            notReceive : []
        };
    },
    watch : {
        data : {
            handler () {
                this.setData();
            }
        }
    },
    mounted () {
        this.setData();
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        },
        showButton (item) {
            const array = [...this.receive, ...this.notReceive];
            for (let i = 0; i < array.length; i++) {
                if (array[i].product_name === item.product_name) {
                    return false;
                }
            }
        },
        showReceivedButton (item) {
            for (let i = 0; i < this.receive.length; i++) {
                if (this.receive[i].product_name === item.product_name) {
                    return true;
                }
            }
        },
        showNotReceivedButton (item) {
            for (let i = 0; i < this.notReceive.length; i++) {
                if (this.notReceive[i].product_name === item.product_name) {
                    return true;
                }
            }
        },
        setData () {
            this.order = this.data.order;
            this.entry_id = this.data.entry_id;
            if (this.data.received) {
                this.receive = this.data.received;
            }
            if (this.data.not_received) {
                this.notReceive = this.data.not_received;
            }
            this.remarks = this.data.remarks;
        },
        setReceived (item) {
            for (let i = 0; i < this.order.items.length; i++) {
                if (this.order.items[i].product_name === item.product_name) {
                    this.order.items[i].received = true;
                    this.receive.push(this.order.items[i]);
                }
            }
        },
        updateList (item) {
            for (let i = 0; i < this.receive.length; i++) {
                if (this.receive[i].product_name === item.product_name) {
                    this.order.items[i].received = false;
                    this.receive.splice(i, 1);
                }
            }
        },
        updateList1 (item) {
            for (let i = 0; i < this.notReceive.length; i++) {
                if (this.notReceive[i].product_name === item.product_name) {
                    this.notReceive.splice(i, 1);
                }
            }
        },
        setNotReceived (item) {
            for (let i = 0; i < this.order.items.length; i++) {
                if (this.order.items[i].product_name === item.product_name) {
                    this.notReceive.push(this.order.items[i]);
                }
            }
        },
        async saveClicked () {
            const data = {};
            data.order_id = this.data.order.order_id;
            data.remarks = this.remarks;
            data.received = this.receive;
            if (this.entry_id) {
                data.entry_id = this.entry_id;
            } else {
                data.entry_id = '';
            }
            if (this.receive.length !== 0) {
                const x = this.receive;
                JSON.stringify(x);
                data.received = x;
            } else {
                data.received = [];
            }
            if (this.notReceive.length !== 0) {
                const y = this.receive;
                JSON.stringify(y);
                data.not_received = y;
            } else {
                data.not_received = [];
            }
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.warehouseAdmin.purchaseEntry.save, { json : JSON.stringify(data) });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Saved Successfully', 'Success', {
                    type : 'success'
                });
                that.$router.push('/warehouse/entry/list/');
                that.loading = false;
            } else {
                that.$notify('Unable to Saved the entry.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        async submitClicked () {
            const data = {};
            data.order_id = this.data.order.order_id;
            data.remarks = this.remarks;
            data.received = this.receive;
            if (this.entry_id) {
                data.entry_id = this.entry_id;
            } else {
                data.entry_id = '';
            }
            if (this.receive.length !== 0) {
                const x = this.receive;
                JSON.stringify(x);
                data.received = x;
            } else {
                data.received = [];
            }
            if (this.notReceive.length !== 0) {
                const y = this.receive;
                JSON.stringify(y);
                data.not_received = y;
            } else {
                data.not_received = [];
            }
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.warehouseAdmin.purchaseEntry.submit, {
                json     : JSON.stringify(data),
                entry_id : that.entry_id,
                remarks  : that.remarks
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Submitted Successfully', 'Success', {
                    type : 'success'
                });
                that.$router.push('/warehouse/entry/list/');
                that.loading = false;
            } else if (json.msg === 'empty list') {
                that.$notify('Unable to submit the entry with empty items!', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            } else {
                that.$notify('Unable to submit the entry.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
