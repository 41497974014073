<template>
    <div>
        <div v-if="!id" class="ml-3 mr-3 mb-5">
            <h4 class="mb-3">Add Purchase</h4>
            <div class="card bs-4 p-1">
                <div>
                    <h5 class="bb-1 p-3">Search </h5>
                </div>
                <div>
                    <b-form @submit.prevent="clearData" class="pl-3 pt-2 pr-3 pb-0" @success="formSuccess" @failure="noDataAvailable"
                            :save-url="searchOrder" ref="form" :save-params="{is_form}"
                            v-slot="{model, loading}">
                        <div class="fl-te-c">
                            <div class="fl-eq">
                                <div class="mt-2">
                                    <h6>Enter your PO ID</h6>
                                </div>
                                <div class="pl-3">
                                    <validated-input name="order_id" :rules="{required: true}"
                                                     v-model="model.order_id" :disabled="loading"/>
                                </div>
                            </div>
                            <div>
                                <btn size="sm" text="Submit" :loading="loading"
                                     loading-text="Submitting..."></btn>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
        <purchase-entry-details-page v-if="data" :data="data"/>
        <div class="round-2 pb-6 text-center" v-if="noData">
            <img style="height: 12rem;" alt="No Data Available" src="../../../../assets/img/no-data.png">
            <p>No data for Purchase Order found</p>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import PurchaseEntryDetailsPage from './DetailsComponent';

export default {
    name       : 'AddComponent',
    components : { PurchaseEntryDetailsPage },
    data () {
        return {
            DataLoading : false,
            loading     : false,
            noData      : false,
            is_form     : true,
            id          : this.$route.params.id,
            searchOrder : urls.warehouseAdmin.purchaseEntry.searchOrder,
            data        : {}
        };
    },
    mounted () {
        if (this.id) {
            this.loadDetails();
        }
    },
    methods : {
        noDataAvailable (response) {
            this.noData = true;
            this.data = {};
        },
        clearData () {
            this.data = {};
        },
        formSuccess (response) {
            this.noData = false;
            this.data = response.data.data;
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.warehouseAdmin.purchaseEntry.searchOrder, {
                order_id : that.id,
                is_form  : false
            });
            const json = response.data;
            if (json.error === false) {
                that.formSuccess(response);
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
