<template>
    <div class="ml-3 mr-3">
        <div v-if="data.order">
            <div class="fl-te-c mb-3">
                <div>
                    <h4>Purchase Details</h4>
                </div>
                <div class="btn-group">
                    <btn @click="$router.push('/warehouse/entry/list/')" size="sm" text="Back"/>
                    <btn size="sm" @click="$refs.orderForm.saveClicked()" text="Save"/>
                    <btn size="sm" @click="$refs.orderForm.submitClicked()" text="Submit"/>
                </div>
            </div>
            <purchase-order-details-table ref="orderForm" :data="items"/>
        </div>
    </div>
</template>

<script>
import PurchaseOrderDetailsTable from './TableComponents/PurchaseOrderDetailsTable';

export default {
    name  : 'PurchaseEntryDetailsPage',
    props : {
        data : { type : Object }
    },
    components : { PurchaseOrderDetailsTable },
    data () {
        return {
            DataLoading  : false,
            items        : {},
            statusOption : [
                { label : 'Approve', value : 'Approve' },
                { label : 'Reject', value : 'Reject' }
            ]
        };
    },
    watch : {
        data : {
            handler () {
                this.setData();
            }
        }
    },
    methods : {
        setData () {
            if (this.data) {
                this.items = this.data;
            }
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
